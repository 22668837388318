import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import OmniBanner from '../../assets/images/omni-fitness/omni-banner.webp';
import OmniOne from '../../assets/images/omni-fitness/omni-1.webp';
import OmniTwo from '../../assets/images/omni-fitness/omni-2.webp';
import OmniThree from '../../assets/images/omni-fitness/omni-3.webp';
import OmniFive from '../../assets/images/omni-fitness/omni-5.webp';

import OmniFourA from '../../assets/images/omni-fitness/omni-4-a.webp';
import OmniFourB from '../../assets/images/omni-fitness/omni-4-b.webp';
import OmniFourC from '../../assets/images/omni-fitness/omni-4-c.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class OmniFitness extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={OmniBanner} alt="Omni Active Fitness banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Personal Training App Featuring Online Workout Programs
                </div>
                <div className="subtitle section-margin">
                  Bringing fitness training straight to your homes
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#00A9E4'}}> OMNI-ACTIVE FITNESS</span>
                </div>
                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="Omni Active Fitness" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build a fitness solution that provides one-on-one virtual training as well as
                      online workout sessions.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="Omni Active Fitness" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed a mobile application, created web portals for admin, clients, and
                      trainers, and designed a new marketing website.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="Omni Active Fitness" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      An industry changing platform that digitally transformed and helped grow their
                      business, with marketing promotions and sponsorships on the horizon.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="Omni Active Fitness" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://www.digitaljournal.com/pr/omni-active-fitness-a-hometown-fitness-business-makes-a-post-pandemic-pivot-to-meet-the-demands-of-fitness-consumers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Omni-Active® Fitness, a hometown fitness business, makes a post-pandemic pivot
                      to meet the demands of fitness consumers
                    </a>{' '}
                    - Digital Journal
                  </p>
                </div>

                <div className="video-container section-margin">
                  <iframe
                    src="https://drive.google.com/file/d/1HBv5nFosGkDpW58hL0YzGUY5h2RkrWNY/preview"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>

                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://www.omniactivefitness.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Omni-Active® Fitness
                      </a>{' '}
                      had been offering on-site and in-home personal training in the Greater
                      Cleveland area since 2012. With the COVID pandemic changing the fitness
                      industry landscape, the company wanted to lead the charge in building a{' '}
                      <a
                        className="description"
                        href="https://www.instagram.com/omniactivefitness/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        fitness platform
                      </a>{' '}
                      that would provide one-on-one training and online workout sessions.
                    </p>
                    <br />
                    <p className="description">
                      To drive this digital transformation, Omni-Active approached Tintash to
                      develop a comprehensive software suite that could be used by their clients and
                      trainers alike.{' '}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-6">
                      <img className="w-100  " src={OmniOne} alt="Omni Active Fitness" />
                    </div>
                  </div>
                  <div className="text-with-image-container section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Omni-Active® Fitness engaged Tintash as their development partner right at the
                      ideation stage. They had the salient features outlined and wanted us to refine
                      their product concept further. Our team started with a discovery phase to
                      visualize the concept, list the unknowns, and propose innovative solutions to
                      the problem sets identified. After finalizing the user stories, wireframes,
                      prospective timelines, and cost estimates, we were able to lock in the scope
                      of work.
                    </p>
                    <br />
                    <p className="description">
                      As we kickstarted the development phase, our design team refined the
                      wireframes into high-fidelity screens while the engineers prototyped product
                      features. The engineering team used an MVP to perform closed-beta testing and
                      collect user feedback, and ensured the production data (accumulated from the
                      system’s usage) remained consistent for our beta users.
                    </p>
                    <br />
                  </div>
                  <div className="text-with-image-container">
                    <div className="image-container-2 d-flex flex-wrap justify-content-center p-0 col-6 mt-3">
                      <img className="w-100" src={OmniTwo} alt="Omni Active Fitness" />
                    </div>
                    <p className="description">
                      Based on user feedback, we built new features and continuously improved the
                      system. One such improvement was building support for hardware devices. It was
                      important for the client to have access to their users’ fitness data for some
                      of the primary features of their application such as, for instance, the daily
                      goals and reporting features. Towards this end, we successfully integrated the
                      mobile application with smart wearables, for both iOS and Android.
                    </p>
                    <br />
                    <p className="description">
                      The client wanted to determine the user influx and view their complete
                      financial information i.e. subscription revenue, payments owed to trainers. To
                      achieve this, we built a complete reporting module for the system. We
                      integrated the financial transactions with RevenueCat, enabling our client to
                      analyze the patterns of users Omni Active was successfully converting by
                      providing the required data points.
                    </p>
                    <br />
                  </div>

                  <div className="col-12 p-0">
                    <p className="description">
                      Nearing project completion, as we prepared submissions for a timely review of
                      the applications before their public launch, we had to additionally ensure
                      compliance with app store guidelines. The client wanted us to implement in-app
                      purchases. However, while Omni-Active® Fitness had monthly and yearly
                      subscription plans ranging from $40 to $4,999, iOS and Android only allowed
                      for in-app purchases not exceeding $999 and $400, respectively.
                    </p>
                    <br />
                    <p className="description">
                      In order to set up a payment mechanism for all subscription plans, our team
                      proposed Stripe integration. Since both Apple and Android prohibit direct
                      in-app use of Stripe, to work around that problem, we implemented it on the
                      web portal. Third-party integrations can be risky but our intelligent
                      implementation got the applications approved by both the app stores in our
                      very first submissions. This bagged a major win for the client who was
                      delighted by our team’s out-of-the-box approach.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100  " src={OmniThree} alt="Omni Active Fitness" />
                    </div>
                  </div>
                  <div className="text-with-image-container">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      Tintash helped Omni Active® build a complete system consisting of web portals
                      for admin, clients, and trainers, as well as mobile applications (both iOS and
                      Android). The system was well-integrated with smart wearables, including Apple
                      and Android watches. The Stripe-enabled payment mechanism, while allowing for
                      all subscription plans, was fully compliant with app stores’ guidelines.
                    </p>
                    <br />
                    <div className="image-container d-flex flex-wrap justify-content-center p-0 col-6 mt-3">
                      <img className="w-100" src={OmniFive} alt="Omni Active Fitness" />
                    </div>
                    <p className="description">
                      With their industry-changing app, Omni-Active® Fitness today offers one-on-one
                      virtual training and online workout sessions with step-by-step instructions
                      and videos to follow to complement their in-person workouts. The founder,
                      Nithanial Decker, says, “With fitness consumers now utilizing a wide array of
                      services and solutions as the world enters a post-pandemic era, our company
                      has pivoted to make change not only to survive but to greatly improve for the
                      future of employees and clients alike.”
                    </p>
                    <br />
                    <p className="description">
                      Tintash designed effective solutions to the various business and functional
                      flow problems throughout the project lifecycle, and continues to work on the
                      product’s maintenance. We also designed a new marketing website to help with
                      the client’s rebranding efforts of their digitally transformed business. The
                      system is serving live customers helping the client grow their business, with
                      marketing promotions and sponsorships on the horizon.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center align-items-center section-margin">
                    <div className="image-description text-center mb-3 col-12">
                      Omni-Active: some in-app screens
                    </div>
                    <img
                      className="w-100 sub-pic col-4"
                      src={OmniFourA}
                      alt="Omni Active Fitness"
                    />

                    <img
                      className="w-100 sub-pic col-4"
                      src={OmniFourB}
                      alt="Omni Active Fitness"
                    />
                    <img
                      className="w-100 sub-pic col-4"
                      src={OmniFourC}
                      alt="Omni Active Fitness"
                    />
                  </div>

                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default OmniFitness;
